import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

class AdministratorService{

    getAll(){
        return axios.get(API_URL+ "/administrator/list");
    }
    getFilter(query) {
        return axios.get(`${API_URL}/administrator/list-filter`, {
            params: {
                query: query,
            }
        });
    }
}

export default new AdministratorService();
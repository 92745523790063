import Home from "./page/home/Home";
import Administrators from "./page/administrator/Administrators";
import Registration from "./page/registration/Registration";
import ConfirmRegister from "./page/registration/ConfirmRegister";
import Contacts from "./page/contacts/Contacts";
import Faq from "./page/faq/Faq";
import NotFound from "./page/notfound/NotFound";
import { Routes,Route } from 'react-router-dom'; 

export default function Main() {
  	return (
		  	<Routes>
		  	    <Route exact path="*" element={<NotFound />} />
		  	    <Route exact path="/error" element={<NotFound />} />
		  		<Route exact path='/' element={< Home />}></Route>
		  		<Route exact path='/home' element={< Home />}></Route>
		  		<Route exact path='/administrator' element={< Administrators />}></Route>
		  		<Route exact path='/registration' element={< Registration />}></Route>
		  		<Route exact path='/contacts' element={< Contacts />}></Route>
		  		<Route exact path='/faq' element={< Faq />}></Route>
		  		<Route exact path='/confirm-administrator' element={< ConfirmRegister />}></Route>
		  		<Route exact path='/confirm-company' element={< ConfirmRegister />}></Route>
		  	</Routes>		  
	);
}
import "./Administrator.css";
import test from '../images/1.jpg';

export default function Administrator({listCardAdmin, name, description, address, cap, comune, provincia }) {
	return (
        <div className={`listing-item ${listCardAdmin ? "list-layout" : ""}`}>
            <article className="geodir-category-listing fl-wrap">
                <div className="geodir-category-img">
                    <img src={test} alt=""/>
                    <div className="overlay"></div>
                </div>
                <div className="geodir-category-content fl-wrap">
                    <a className="listing-geodir-category" href="#">Conferma Dati</a>
                    <div className="listing-avatar"><a href="#"><img src={test} alt=""/></a>
                        <span className="avatar-tooltip"><strong>Lisa Smith</strong></span>
                    </div>
                    <h3><a href="#">{name}</a></h3>
                    <p>{description}</p>
                    <div className="geodir-category-options fl-wrap">
                        <div className="geodir-category-location"><a href="#"><i className="fa fa-map-marker" aria-hidden="true"></i> {address}, {comune}({provincia}) {cap}</a></div>
                    </div>
                </div>
            </article>
        </div>
	);
}